<template>
  <div class="flex w-full flex-1 animate-pulse h-[48px]">
    <div class="flex-1 space-y-3 py-1">
      <div class="h-4 rounded-sm bg-[#F5F5F5] w-2/4" />
      <div v-if="!alt" class="h-4 rounded-sm bg-[#F5F5F5]" />
      <div v-else class="flex space-x-4">
        <div class="h-4 rounded-sm bg-[#F5F5F5] flex-1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alt: {
      type: Boolean,
    },
  },
};
</script>
